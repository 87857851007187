/*#### SINGLE SERVICE ####*/
.single_service {
  margin-top: 130px;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    .breadcrumb {
      padding-right: 30px;
    }

    .back {
      a {
        color: #5A5B5E;
        letter-spacing: 5px;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  .single_service_container {
    border-radius: 25px;
    border: 1px solid #5A5B5E;
    //border-right: none;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin: 30px 0 60px;

    @media (max-width: 65em){
      flex-wrap: wrap;
      border-right: 1px solid #5A5B5E;
    }


    .single_service_description {
      width: 40%;
      padding: 25px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-right: 20px solid #F36A1F;

      @media (max-width: 65em){
        flex-basis: 100%;
        border-right: none;
        border-bottom: 20px solid #F36A1F;
      }

      header {

        h1 {
          font-size: 1.8em;
          color: #F36A1F;
          font-weight: normal;
          line-height: 1.2;

          strong {
            display: block;
            font-weight: 700;
          }
        }

        p{
          color: #5A5B5E;
          margin-top: 20px;
        }
      }


    }

    .single_service_slide {
      width: 60%;
      display: flex;

      @media (max-width: 65em){
        flex-basis: 100%;

        .swiper-slide{
          a, img{
            width: 100%;
          }
        }

      }
    }
  }

  aside{
    .container{

      .single_service_related{
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 100px;

        h2{
          flex-basis: 100%;
          font-size: 2em;
          color: #F36A1F;
          line-height: 1;
          margin-bottom: 30px;

          @media (max-width: 60em){
            text-align: center;
          }
        }

        .single_service_related_items{
          flex-basis: 100%;
          display: flex;

          @media (max-width: 60em){
            flex-wrap: wrap;
            justify-content: center;
          }

          .single_service_related_item{
            flex-basis: calc(33.33% - 10px);
            margin-right: 15px;
            border-radius: 25px;
            border: 1px solid #5A5B5E;
            padding: 25px 25px 70px;
            position: relative;

            @media (max-width: 60em){
              flex-basis: 100%;
              max-width: 400px;
              margin-right: 0;
              margin-bottom: 30px;

              &:last-of-type{
                margin-bottom: 0;
              }
            }

            &:nth-of-type(3n+0){
              margin-right: 0;
            }

            h1{
              font-size: 1.8em;
              color: #F36A1F;
              line-height: 1;
              margin-bottom: 20px;
            }

            a{
              border-radius: 25px;
              border: 3px solid #F36A1F;
              color: #F36A1F;
              font-weight: 700;
              padding: 5px 20px 5px 35px;
              position: absolute;
              bottom: 0;
              margin: 10px 0;

              &:hover{
                border-color: #FF5C03;
                color: #FF5C03;
              }

              span{
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }

}