/*####### HEADER ######*/
.main_header {
  border-top: 22px solid #F36A1F;
  position: relative;

  .main_header_container {
    position: absolute;
    width: 100%;
    top: 0;
    margin-top: -22px;
    z-index: 99999;

    .main_header_navigation {
      margin: 0 auto;
      background: #FFFFFF;
      width: 90%;
      max-width: 1440px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0 25px;

      .main_header_logo {
        /*1184px*/
        @media screen and (max-width: 74em) {
          flex-basis: 23%;
        }

        /*1056px*/
        @media screen and (max-width: 66em) {
          flex-basis: 100%;
        }
      }

      /*### NAV ###*/
      .main_header_nav {
        flex-basis: 100%;
        display: flex;
        justify-content: flex-end;

        /*1184px*/
        @media screen and (max-width: 74em) {
          flex-basis: 77%;
        }

        nav {

          .main_header_nav_close {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            margin: 40px 60px 0 0;
            opacity: 0.9;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }

          /*1056px*/
          @media screen and (max-width: 66em) {
            display: block;
            position: fixed;
            overflow: auto;
            background: rgba(243, 106, 31, 0.9);
            top: 0;
            left: 100%;
            width: 480px;
            height: 100%;
            max-width: 95%;
            //margin-left: -350px;
            z-index: 99999;
            padding: 100px 0 50px;

            .main_header_nav_close {
              display: block;

              img {
                width: 40px;
              }
            }
          }


        }

        .main_header_nav_ul {
          display: flex;

          /*1056px*/
          @media screen and (max-width: 66em) {
            flex-wrap: wrap;
          }

          li {
            padding: 0 20px;
            position: relative;

            /*1184px*/
            @media screen and (max-width: 74em) {
              padding: 0 15px;
            }

            /*1056px*/
            @media screen and (max-width: 66em) {
              flex-basis: 100%;
              text-align: center;
              margin-bottom: 10px;

              &:last-of-type {
                margin-bottom: 0;
              }
            }

            &.active, &:hover {
              a {
                color: #F36A1F;

                /*1056px*/
                @media screen and (max-width: 66em) {
                  color: #FFFFFF;
                }

                span {
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 12px solid #F36A1F;

                  /*1056px*/
                  @media screen and (max-width: 66em) {
                    display: none;
                  }
                }
              }
            }

            &:hover {
              .main_header_nav_ul_sub {
                display: block;
              }
            }

            h2 {
              font-weight: 600;
              font-size: 22px;

              /*1312px*/
              @media screen and (max-width: 82em) {
                font-size: 18px;
              }

              /*1056px*/
              @media screen and (max-width: 66em) {
                font-size: 28px;
              }

              a {
                color: #5A5B5E;
                position: relative;

                /*1056px*/
                @media screen and (max-width: 66em) {
                  color: #FFFFFF;
                }

                span {
                  width: 0;
                  height: 0;
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  margin-left: -5px;
                  margin-bottom: -12px;
                  z-index: 1;
                }

                &:hover {
                  color: #F36A1F;

                  /*1056px*/
                  @media screen and (max-width: 66em) {
                    color: #7B7C7E;
                    text-shadow: 1px 0 2px #FFFFFF;
                  }

                  span {
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 12px solid #F36A1F;
                  }
                }
              }
            }

            /* subcategories*/
            .main_header_nav_ul_sub {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              margin-top: 25px;
              padding: 60px 30px 80px;
              border-bottom-left-radius: 25px;
              border-bottom-right-radius: 25px;
              border-top-right-radius: 25px;
              background: #FFFFFF;

              /*1056px*/
              @media screen and (max-width: 66em) {
                display: block;
                position: relative;
                padding: 0;
                margin-top: 0;
                background: none;

                .main_header_nav_ul_sub_container {
                  margin: 0 auto;
                }
              }

              li.subs {
                &:hover {
                  width: 500px;

                  /*1300px*/
                  @media screen and (max-width: 81.250em) {
                    width: 420px;
                  }
                }
              }

              li {
                margin-bottom: 20px;
                text-align: center;
                padding: 4px 8px;
                border: 2px solid transparent;
                position: relative;

                .main_header_nav_ul_sub_container {
                  width: 150px;
                  color: #f36a1f;
                  border-radius: 25px;
                  position: relative;
                  border: 2px solid transparent;

                  &:hover {
                    border: 2px solid #f36a1f;
                  }

                }

                &:hover {

                  a {
                    color: #F36A1F;
                  }
                }

                /*1056px*/
                @media screen and (max-width: 66em) {
                  border: none;
                  margin-bottom: 0;

                  &:last-of-type {
                    margin-bottom: 10px !important;

                    a {
                      border-bottom: 2px solid rgba(235, 235, 235, 0.15) !important;
                    }
                  }
                }

                &.active, &:hover {
                  //color: #F36A1F;
                  //border: 2px solid #F36A1F;
                  //border-radius: 25px;

                  /*1056px*/
                  @media screen and (max-width: 66em) {
                    border: none;
                  }

                  .main_header_nav_ul_sub_ul {
                    display: block;

                    /*1056px*/
                    @media screen and (max-width: 66em) {
                      display: none;
                    }
                  }

                  span {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -8px;
                    margin-right: -20px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 12px solid #F36A1F;
                    z-index: 1;
                  }
                }

                &:last-of-type {
                  margin-bottom: 0;
                }

                a {

                  color: #5A5B5E;
                  font-weight: 600;

                  &:hover {
                    color: #F36A1F;
                  }

                  /*1056px*/
                  @media screen and (max-width: 66em) {
                    color: #ebebeb;
                    text-shadow: 1px 0 2px #333;
                  }

                }
              }

              .main_header_nav_ul_sub_ul {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                margin-right: -370px;
                margin-top: -60px;
                width: 350px;
                background: #FFFFFF;
                padding: 50px 30px 0;
                border-radius: 25px;
                z-index: 99998;

                /*1300px*/
                @media screen and (max-width: 81.250em) {
                  margin-right: -220px;
                  width: 200px;
                  padding: 50px 30px 0 10px;
                }

                li {
                  text-align: left;
                  margin-bottom: 10px;
                  border-color: transparent;


                  a {
                    color: #5A5B5E;
                    font-weight: normal;
                  }

                  &.active, &:hover {
                    border-color: transparent;

                    a {
                      color: #F36A1F;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .main_header_nav_mobile {
        display: none;
        border: 4px solid #F36A1F;
        border-radius: 50%;
        padding: 16px 12px;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        /*1056px*/
        @media screen and (max-width: 66em) {
          display: block;
        }

        img {
          width: 30px;
        }
      }
    }
  }
}

/*CONTACT BUTTON ### fixed */
.contact_link {
  background: #F36A1F;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 130px;
  padding: 8px 25px 8px 15px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 1px solid #ffffff;
  z-index: 99998;
  font-weight: 700;
  color: #FFFFFF;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}

/*####### FOOTER ######*/
/* newsletter */
.main_newsletter {
  padding: 50px 0;
  background: #F36A1F;

  .container {
    display: flex;
    align-items: center;

    /*960px*/
    @media (max-width: 60em) {
      flex-wrap: wrap;
      justify-content: center;
    }

    header {
      text-align: right;
      width: 360px;

      /*960px*/
      @media (max-width: 60em) {
        width: auto;
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 30px;
      }

      h1 {
        font-size: 2em;
        font-weight: normal;
        line-height: 1;
        color: #FFFFFF;
      }
    }

    .main_newsletter_form_container {
      flex-basis: 100%;

      form {
        display: flex;
        justify-content: flex-end;
        padding-left: 80px;

        /*960px*/
        @media (max-width: 60em) {
          padding-left: 0;
        }

        /*640px*/
        @media (max-width: 40em) {
          flex-wrap: wrap;
          padding-left: 0;
        }

        label {
          flex-basis: calc(50% - 5px);
          margin-right: 10px;

          /*640px*/
          @media (max-width: 40em) {
            flex-basis: 100%;
            margin-right: 0;
            margin-bottom: 15px;
          }

          input {
            width: 100%;
            padding: 10px 20px;
            border-radius: 25px;
            font-size: 1.2em;
            border: none;
            font-family: 'Titillium Web', sans-serif;
          }
        }

        button {
          border-radius: 25px;
          color: #FFFFFF;
          background: #5A5B5E;
          font-weight: 700;
          padding: 6px 12px;
          border: 2px solid #5A5B5E;
          width: 145px;
          font-size: 1.1em;

          /*640px*/
          @media (max-width: 40em) {
            padding: 12px 12px;
            margin: 0 auto;
          }

          &:hover {
            border-color: #555555;
            background: #6B6C6E;
          }
        }
      }
    }
  }
}

/*#### MAIN FOOTER ####*/
.main_footer {
  background: #5A5B5E;
  padding-top: 100px;

  .main_footer_contact {
    margin: 0 auto;
    background: #FFFFFF;
    width: 90%;
    max-width: 1160px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;

    /*1072px*/
    @media (max-width: 67em) {
      flex-wrap: wrap;
      padding: 30px 60px 60px;
    }

    .main_footer_contact_item {
      display: flex;
      align-items: center;
      color: #5A5B5E;

      /*1072px*/
      @media (max-width: 67em) {
        flex-basis: 100%;
        //justify-content: center;
        max-width: 300px;
        margin: 0 auto 20px;

        &:first-of-type {
          border-top: 4px solid #F36A1F;
          padding-top: 60px;

        }
      }

      img {
        width: 20px;
        margin-right: 20px;
      }

      a {
        color: #5A5B5E;
        word-break: break-word;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}