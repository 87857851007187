@charset "UTF-8";
/*RESET*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

/*ELEMENTS*/
img {
  max-width: 100%;
  display: block; }

img a {
  border: none; }

ul {
  list-style: none; }

a,
button {
  text-decoration: none;
  cursor: pointer; }

embed,
video,
iframe,
iframe[style] {
  max-width: 100%;
  height: auto; }

.embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.embed iframe, .embed object, .embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.strike {
  text-decoration: line-through; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #555555; }

input {
  outline: 0; }

textarea {
  resize: vertical;
  outline: 0; }

/*ICONS NORMALIZE*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  position: relative;
  top: .125em;
  margin-right: .4em; }

.icon-notext:before {
  top: 0;
  margin-right: 0; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*DEFAULT PAGINATOR*/
.paginator {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  padding: 20px 0; }

.paginator a, .paginator span {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #cccccc;
  border-right: 0;
  color: #555555;
  font-weight: bold;
  text-decoration: none;
  transition-duration: 0.5s; }

.paginator a:first-of-type {
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px; }

.paginator a:last-of-type {
  border-right: 1px solid #cccccc;
  -webkit-border-radius: 0 5px var(--radius-normal) 5px;
  -moz-border-radius: 0 5px var(--radius-normal) 5px;
  border-radius: 0 5px var(--radius-normal) 5px; }

.paginator a:hover {
  background: var(--color-themeB);
  background-size: 200%;
  color: #FBFBFB; }

.paginator span {
  background: #cccccc; }

/*WYSH HTMLCHARS*/
.htmlchars {
  color: var(--color-default); }

.htmlchars :first-child {
  margin-top: 0; }

.htmlchars h2,
.htmlchars h3,
.htmlchars h4,
.htmlchars h5 {
  margin: 40px 0 20px 0;
  font-weight: var(--weight-bold); }

.htmlchars h2 {
  font-size: 1.6em; }

.htmlchars h3 {
  font-size: 1.4em; }

.htmlchars h4 {
  font-size: 1.2em; }

.htmlchars h5 {
  font-size: 1.1em; }

.htmlchars p {
  margin-top: 10px; }

.htmlchars ul,
.htmlchars ol {
  margin: 20px 0 20px 40px; }

.htmlchars ul {
  list-style: circle; }

.htmlchars ol li,
.htmlchars ul li {
  margin-top: 5px; }

.htmlchars a {
  color: var(--color-themeB);
  font-weight: var(--weight-bold);
  text-decoration: none; }

.htmlchars a:hover {
  text-decoration: underline; }

.htmlchars img {
  margin-top: 30px;
  -webkit-border-radius: var(--radius-medium);
  -moz-border-radius: var(--radius-medium);
  border-radius: var(--radius-medium); }

/*SOCIAL SHARE*/
.social_share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin: 30px 0 60px;
  background: #FBFBFB;
  -webkit-border-radius: var(--radius-medium);
  -moz-border-radius: var(--radius-medium);
  border-radius: var(--radius-medium);
  border-bottom: 3px solid var(--color-green); }

.social_share_title {
  font-size: var(--font-normal);
  font-weight: var(--weight-normal); }

.social_share_medias {
  display: flex; }

.social_share_medias div {
  margin-right: 10px; }

/*EMPTY CONTENT*/
.empty_content {
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  color: var(--color-default); }

.empty_content_cover {
  width: 500px; }

.empty_content_title {
  font-size: 2em;
  font-weight: var(--weight-bold); }

.empty_content_desc {
  font-size: 1.1em;
  margin: 10px 0 40px 0; }

.title_image {
  width: 300px;
  max-width: 100%;
  margin: 0 auto 30px auto; }

.empty_content_btn {
  display: inline-block;
  padding: 20px 40px;
  font-weight: var(--weight-bold);
  color: #ffffff;
  text-decoration: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: var(--font-large); }

/*CONTAINER*/
.container {
  display: block;
  width: 1366px;
  max-width: 90%;
  margin: 0 auto; }

/*MARGIN*/
.m-top10 {
  margin-top: 10px; }

.m-bottom10 {
  margin-bottom: 10px; }

.m-top20 {
  margin-top: 20px; }

.m-bottom20 {
  margin-bottom: 20px; }

.m-top30 {
  margin-top: 30px; }

.m-bottom30 {
  margin-bottom: 30px; }

/*PADDING*/
.p-top10 {
  padding-top: 10px; }

.p-bottom10 {
  padding-top: 10px; }

.p-top20 {
  padding-top: 20px; }

.p-bottom20 {
  padding-top: 20px; }

.p-top30 {
  padding-top: 30px; }

.p-bottom30 {
  padding-top: 30px; }

/*EFFECTS*/
.transition {
  transition-duration: 0.5s; }

.radius {
  -webkit-border-radius: var(--radius-normal);
  -moz-border-radius: var(--radius-normal);
  border-radius: var(--radius-normal); }

.radius-medium {
  -webkit-border-radius: var(--radius-medium);
  -moz-border-radius: var(--radius-medium);
  border-radius: var(--radius-medium); }

.radius-large {
  -webkit-border-radius: var(--radius-large);
  -moz-border-radius: var(--radius-large);
  border-radius: var(--radius-large); }

.rounded {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }

.text-shadow {
  text-shadow: 1px 1px 1px #333; }

/*GRADIENTS*/
.gradient {
  background-size: 200% auto;
  transition-duration: 0.5s; }

.gradient-green {
  background-image: var(--gradient-green); }

.gradient-blue {
  background-image: var(--gradient-blue); }

.gradient-yellow {
  background-image: var(--gradient-yellow); }

.gradient-orange {
  background-image: var(--gradient-orange); }

.gradient-red {
  background-image: var(--gradient-red); }

.gradient-pink {
  background-image: var(--gradient-pink); }

.gradient-turquoise {
  background-image: var(--gradient-turquoise); }

.gradient-hover:hover {
  background-position: right center; }

/*BACKGROUNDS*/
.bg-themeA {
  background: var(--color-themeA); }

.bg-themeB {
  background: var(--color-themeB); }

.bg-white {
  background: #ffffff; }

.bg-gray-light {
  background: #FBFBFB; }

.bg-gray {
  background: #f4f4f4; }

.bg-orange {
  background: #FFBF80; }

/*TEXTS*/
/*ALIGNS*/
.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-upper {
  text-transform: uppercase; }

.text-lower {
  text-transform: lowercase; }

/*FONT SIZE*/
.font-zero {
  font-size: 0; }

/*DISPLAYS*/
.ds-none {
  display: none; }

.ds-block {
  display: block; }

.ds-inline {
  display: inline; }

.ds-inline-block {
  display: inline-block; }

.ds-flex {
  display: flex; }

/*
FLEXBOX
*/
.flex {
  display: flex;
  flex-wrap: wrap; }

.flex .flex-1 {
  flex-basis: 100%; }

.flex .flex-2 {
  flex-basis: calc(50% - 40px);
  margin: 20px; }

.flex .flex-3 {
  flex-basis: calc(33.33% - 30px);
  margin: 15px; }

.flex .flex-4 {
  flex-basis: calc(25% - 20px);
  margin: 10px; }

/*SLIDE SWIPER*/
.swiper-container-slide {
  width: 100%;
  height: 100%; }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.message_register {
  padding: 10px;
  background: #666666;
  color: #FFFFFF;
  font-size: 0.875em;
  text-transform: uppercase; }

.message_register.success {
  background: var(--color-green); }

.message_register.info {
  background: var(--color-blue); }

.message_register.alert {
  background: var(--color-yellow); }

.message_register.error {
  background: var(--color-red); }

.message span {
  display: inline-block;
  margin-bottom: 10px; }

.message .btn {
  background: #f4f4f4;
  color: #999999;
  padding: 10px 20px;
  font-size: 0.875em;
  text-transform: uppercase;
  display: inline-block; }

.message .btn.success {
  --color: var(--color-green);
  color: #FFFFFF;
  background: var(--color); }

.message .btn.info {
  --color: var(--color-blue);
  color: #FFFFFF;
  background: var(--color); }

.message .btn.alert {
  --color: var(--color-yellow);
  color: #FFFFFF;
  background: var(--color); }

.message .btn.error {
  --color: var(--color-red);
  color: #FFFFFF;
  background: var(--color); }

/*AJAX RESPONSES MESSAGES*/
.ajax_response {
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 300px;
  max-width: 100%; }

.ajax_response .message {
  color: #ffffff;
  font-size: var(--font-min);
  font-weight: var(--weight-normal);
  overflow: hidden;
  border: none;
  margin: 20px 20px 15px 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 15px 20px 15px;
  background: #333333;
  /*margin-bottom: 15px;*/
  position: relative;
  -webkit-border-radius: var(--radius-normal);
  -moz-border-radius: var(--radius-normal);
  border-radius: var(--radius-normal);
  cursor: pointer; }

.ajax_response .message:before {
  flex-basis: 0;
  margin: -5px 15px 0 0;
  font-size: calc(var(--font-max) * 1.2);
  color: rgba(0, 0, 0, 0.5); }

.ajax_response .message_time {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 4%;
  height: 5px;
  background: rgba(0, 0, 0, 0.5); }

.ajax_response .message.success {
  background: var(--color-green); }

.ajax_response .message.info {
  background: var(--color-blue); }

.ajax_response .message.warning {
  background: var(--color-yellow); }

.ajax_response .message.error {
  background: var(--color-red); }

/*form*/
form .label-100,
form .label-50 {
  flex-basis: 100%;
  display: flex;
  align-items: center; }

form .label-100 label {
  flex-basis: 100%; }

form .label-50 label {
  flex-basis: calc(50% - 10px);
  margin: 0 10px; }

form .label-50 label:first-of-type {
  margin-left: 0; }

form .label-50 label:last-of-type {
  margin-right: 0; }

/*modal*/
/*APP MODAL*/
.app_modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: var(--area-box) 0;
  background: rgba(97, 148, 160, 0.5);
  z-index: var(--index-modal);
  display: none;
  overflow-y: auto; }

.app_modal .message {
  text-align: center;
  margin-bottom: var(--area-normal); }

.app_modal_box {
  display: none;
  margin: auto;
  width: 500px;
  max-width: 94%;
  padding: calc(var(--area-box) * 1.5);
  background: #ffffff;
  -webkit-border-radius: var(--radius-normal);
  -moz-border-radius: var(--radius-normal);
  border-radius: var(--radius-normal); }

.app_modal_box .title {
  font-size: var(--font-large);
  font-weight: var(--weight-light);
  color: var(--color-default);
  margin-bottom: var(--area-normal);
  padding-bottom: var(--area-normal);
  border-bottom: 1px solid #EEEEEE; }

.app_modal_box p {
  margin: 0 0 15px 0; }

.app_modal_box form p {
  color: #006699; }

.app_modal_box form p span {
  text-transform: uppercase;
  font-size: 0.875em;
  color: #999999; }

.app_modal_box form p span.no-style {
  text-transform: none;
  font-size: 1em;
  color: #006699; }

.app_modal_box .modal_back {
  display: inline-block;
  margin-top: 15px;
  color: #FFBF80;
  font-weight: bold;
  font-size: 0.875em; }

.app_modal_box .modal_back:hover {
  color: #C16100; }

.app_modal_actions {
  display: flex;
  align-items: center; }

.app_modal_actions .modal_back {
  flex-basis: 30%; }

.app_modal_login_register_sign {
  display: none; }

.app_modal_actions .app_modal_register {
  flex-basis: 70%;
  text-align: right;
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875em;
  color: var(--color-orange);
  cursor: pointer; }

.app_modal_actions .app_modal_register:hover {
  color: #C16100; }

/* FORM */
.app_form label {
  flex-basis: 100%;
  display: block;
  margin-bottom: var(--area-normal);
  cursor: pointer; }

.app_form .field {
  display: block;
  margin-bottom: 5px;
  font-size: var(--font-small);
  color: #888888; }

.app_form textarea,
.app_form select,
.app_form input {
  font-size: 0.875em;
  color: #888888;
  border: 1px solid #e3e3e3;
  display: block;
  background: none;
  width: 100%;
  padding: 10px;
  background: #f3f3f3;
  outline: none; }

.app_form select {
  cursor: pointer; }

.app_form textarea {
  resize: vertical; }

.app_form input[type="date"] {
  -webkit-appearance: none;
  font-size: 1em;
  font-family: "Open Sans", serif;
  padding: 7px 10px;
  font-weight: var(--font-normal);
  color: #888888; }

.app_form select {
  -webkit-appearance: none;
  -moz-appearance: none; }

.app_form .label_group {
  display: flex;
  width: 100%; }

.app_form .label_group label {
  flex-basis: calc(50% - var(--area-small)); }

.app_form .label_group label:first-child {
  margin-right: var(--area-normal); }

.app_form .label_check label {
  display: inline-block;
  margin-left: var(--area-normal);
  font-size: var(--font-small);
  margin-top: 5px;
  color: #888888; }

.app_form .label_check label input {
  display: none; }

.app_form .label_check label:before {
  content: "✓";
  font-weight: var(--weight-black);
  color: #ffffff;
  text-align: center;
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 2px;
  background: #ffffff;
  border: 2px solid #cccccc;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -3px;
  font-size: 1.1em;
  line-height: 0.9;
  -webkit-border-radius: var(--radius-normal);
  -moz-border-radius: var(--radius-normal);
  border-radius: var(--radius-normal); }

.app_form .label_check label.check:before {
  background: var(--color-green);
  border-color: var(--color-green); }

.app_form .label_check label:first-of-type {
  margin-left: 0; }

.app_form .btn {
  display: block;
  width: 100%;
  padding: 15px;
  /*border: none;*/
  cursor: pointer;
  /*background: var(--color-green);*/
  margin-top: var(--area-small);
  color: #ffffff;
  font-size: var(--font-normal);
  text-transform: uppercase;
  font-weight: var(--weight-black); }

.app_form .btn:hover {
  /*background: var(--hover-color-green);*/ }

.app_form .btn_inline {
  display: inline-block;
  width: auto;
  padding: 15px 30px;
  margin: 0 var(--area-small); }

.overflow-hidden {
  overflow: hidden; }

.htmlchars .btn {
  color: #FFFFFF; }

.htmlchars .btn_cta_blue {
  background: #00ABFD; }

.htmlchars .btn_cta_blue:hover {
  background: #0083C1; }

.htmlchars .btn_cta_green {
  background: #00B75B; }

.htmlchars .btn_cta_green:hover {
  background: #008844; }

.htmlchars .btn_cta_yellow {
  background: #F5B946; }

.htmlchars .btn_cta_yellow:hover {
  background: #9c6a23; }

.htmlchars .btn_cta_red {
  background: #F76C82; }

.htmlchars .btn_cta_red:hover {
  background: #D94352; }

.p-relative {
  position: relative; }

.p-absolute {
  position: absolute; }

.arrow-right {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 12px solid #F36A1F; }

.no-banner {
  margin: 120px 0 100px;
  border-top: 22px solid #f36a1f; }

.swal2-styled.swal2-confirm {
  background-color: #f36a1f; }

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(246, 106, 31, 0.5); }

/*####### HEADER ######*/
.main_header {
  border-top: 22px solid #F36A1F;
  position: relative; }
  .main_header .main_header_container {
    position: absolute;
    width: 100%;
    top: 0;
    margin-top: -22px;
    z-index: 99999; }
    .main_header .main_header_container .main_header_navigation {
      margin: 0 auto;
      background: #FFFFFF;
      width: 90%;
      max-width: 1440px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px; }
    .main_header .main_header_container .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0 25px;
      /*### NAV ###*/ }
      .main_header .main_header_container .container .main_header_logo {
        /*1184px*/
        /*1056px*/ }
        @media screen and (max-width: 74em) {
          .main_header .main_header_container .container .main_header_logo {
            flex-basis: 23%; } }
        @media screen and (max-width: 66em) {
          .main_header .main_header_container .container .main_header_logo {
            flex-basis: 100%; } }
      .main_header .main_header_container .container .main_header_nav {
        flex-basis: 100%;
        display: flex;
        justify-content: flex-end;
        /*1184px*/ }
        @media screen and (max-width: 74em) {
          .main_header .main_header_container .container .main_header_nav {
            flex-basis: 77%; } }
        .main_header .main_header_container .container .main_header_nav nav {
          /*1056px*/ }
          .main_header .main_header_container .container .main_header_nav nav .main_header_nav_close {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            margin: 40px 60px 0 0;
            opacity: 0.9;
            cursor: pointer; }
            .main_header .main_header_container .container .main_header_nav nav .main_header_nav_close:hover {
              opacity: 1; }
          @media screen and (max-width: 66em) {
            .main_header .main_header_container .container .main_header_nav nav {
              display: block;
              position: fixed;
              overflow: auto;
              background: rgba(243, 106, 31, 0.9);
              top: 0;
              left: 100%;
              width: 480px;
              height: 100%;
              max-width: 95%;
              z-index: 99999;
              padding: 100px 0 50px; }
              .main_header .main_header_container .container .main_header_nav nav .main_header_nav_close {
                display: block; }
                .main_header .main_header_container .container .main_header_nav nav .main_header_nav_close img {
                  width: 40px; } }
        .main_header .main_header_container .container .main_header_nav .main_header_nav_ul {
          display: flex;
          /*1056px*/ }
          @media screen and (max-width: 66em) {
            .main_header .main_header_container .container .main_header_nav .main_header_nav_ul {
              flex-wrap: wrap; } }
          .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li {
            padding: 0 20px;
            position: relative;
            /*1184px*/
            /*1056px*/
            /* subcategories*/ }
            @media screen and (max-width: 74em) {
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li {
                padding: 0 15px; } }
            @media screen and (max-width: 66em) {
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li {
                flex-basis: 100%;
                text-align: center;
                margin-bottom: 10px; }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li:last-of-type {
                  margin-bottom: 0; } }
            .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li.active a, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li:hover a {
              color: #F36A1F;
              /*1056px*/ }
              @media screen and (max-width: 66em) {
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li.active a, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li:hover a {
                  color: #FFFFFF; } }
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li.active a span, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li:hover a span {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 12px solid #F36A1F;
                /*1056px*/ }
                @media screen and (max-width: 66em) {
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li.active a span, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li:hover a span {
                    display: none; } }
            .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li:hover .main_header_nav_ul_sub {
              display: block; }
            .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 {
              font-weight: 600;
              font-size: 22px;
              /*1312px*/
              /*1056px*/ }
              @media screen and (max-width: 82em) {
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 {
                  font-size: 18px; } }
              @media screen and (max-width: 66em) {
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 {
                  font-size: 28px; } }
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 a {
                color: #5A5B5E;
                position: relative;
                /*1056px*/ }
                @media screen and (max-width: 66em) {
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 a {
                    color: #FFFFFF; } }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 a span {
                  width: 0;
                  height: 0;
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  margin-left: -5px;
                  margin-bottom: -12px;
                  z-index: 1; }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 a:hover {
                  color: #F36A1F;
                  /*1056px*/ }
                  @media screen and (max-width: 66em) {
                    .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 a:hover {
                      color: #7B7C7E;
                      text-shadow: 1px 0 2px #FFFFFF; } }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li h2 a:hover span {
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 12px solid #F36A1F; }
            .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              margin-top: 25px;
              padding: 60px 30px 80px;
              border-bottom-left-radius: 25px;
              border-bottom-right-radius: 25px;
              border-top-right-radius: 25px;
              background: #FFFFFF;
              /*1056px*/ }
              @media screen and (max-width: 66em) {
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub {
                  display: block;
                  position: relative;
                  padding: 0;
                  margin-top: 0;
                  background: none; }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_container {
                    margin: 0 auto; } }
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.subs:hover {
                width: 500px;
                /*1300px*/ }
                @media screen and (max-width: 81.25em) {
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.subs:hover {
                    width: 420px; } }
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li {
                margin-bottom: 20px;
                text-align: center;
                padding: 4px 8px;
                border: 2px solid transparent;
                position: relative;
                /*1056px*/ }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li .main_header_nav_ul_sub_container {
                  width: 150px;
                  color: #f36a1f;
                  border-radius: 25px;
                  position: relative;
                  border: 2px solid transparent; }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li .main_header_nav_ul_sub_container:hover {
                    border: 2px solid #f36a1f; }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:hover a {
                  color: #F36A1F; }
                @media screen and (max-width: 66em) {
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li {
                    border: none;
                    margin-bottom: 0; }
                    .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:last-of-type {
                      margin-bottom: 10px !important; }
                      .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:last-of-type a {
                        border-bottom: 2px solid rgba(235, 235, 235, 0.15) !important; } }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.active, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:hover {
                  /*1056px*/ }
                  @media screen and (max-width: 66em) {
                    .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.active, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:hover {
                      border: none; } }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.active .main_header_nav_ul_sub_ul, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:hover .main_header_nav_ul_sub_ul {
                    display: block;
                    /*1056px*/ }
                    @media screen and (max-width: 66em) {
                      .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.active .main_header_nav_ul_sub_ul, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:hover .main_header_nav_ul_sub_ul {
                        display: none; } }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li.active span, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:hover span {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -8px;
                    margin-right: -20px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 12px solid #F36A1F;
                    z-index: 1; }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li:last-of-type {
                  margin-bottom: 0; }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li a {
                  color: #5A5B5E;
                  font-weight: 600;
                  /*1056px*/ }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li a:hover {
                    color: #F36A1F; }
                  @media screen and (max-width: 66em) {
                    .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub li a {
                      color: #ebebeb;
                      text-shadow: 1px 0 2px #333; } }
              .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                margin-right: -370px;
                margin-top: -60px;
                width: 350px;
                background: #FFFFFF;
                padding: 50px 30px 0;
                border-radius: 25px;
                z-index: 99998;
                /*1300px*/ }
                @media screen and (max-width: 81.25em) {
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul {
                    margin-right: -220px;
                    width: 200px;
                    padding: 50px 30px 0 10px; } }
                .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul li {
                  text-align: left;
                  margin-bottom: 10px;
                  border-color: transparent; }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul li a {
                    color: #5A5B5E;
                    font-weight: normal; }
                  .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul li.active, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul li:hover {
                    border-color: transparent; }
                    .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul li.active a, .main_header .main_header_container .container .main_header_nav .main_header_nav_ul li .main_header_nav_ul_sub .main_header_nav_ul_sub_ul li:hover a {
                      color: #F36A1F; }
      .main_header .main_header_container .container .main_header_nav_mobile {
        display: none;
        border: 4px solid #F36A1F;
        border-radius: 50%;
        padding: 16px 12px;
        opacity: 0.8;
        cursor: pointer;
        /*1056px*/ }
        .main_header .main_header_container .container .main_header_nav_mobile:hover {
          opacity: 1; }
        @media screen and (max-width: 66em) {
          .main_header .main_header_container .container .main_header_nav_mobile {
            display: block; } }
        .main_header .main_header_container .container .main_header_nav_mobile img {
          width: 30px; }

/*CONTACT BUTTON ### fixed */
.contact_link {
  background: #F36A1F;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 130px;
  padding: 8px 25px 8px 15px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 1px solid #ffffff;
  z-index: 99998;
  font-weight: 700;
  color: #FFFFFF;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center; }
  .contact_link img {
    width: 28px;
    height: 28px;
    margin-right: 10px; }

/*####### FOOTER ######*/
/* newsletter */
.main_newsletter {
  padding: 50px 0;
  background: #F36A1F; }
  .main_newsletter .container {
    display: flex;
    align-items: center;
    /*960px*/ }
    @media (max-width: 60em) {
      .main_newsletter .container {
        flex-wrap: wrap;
        justify-content: center; } }
    .main_newsletter .container header {
      text-align: right;
      width: 360px;
      /*960px*/ }
      @media (max-width: 60em) {
        .main_newsletter .container header {
          width: auto;
          flex-basis: 100%;
          text-align: center;
          margin-bottom: 30px; } }
      .main_newsletter .container header h1 {
        font-size: 2em;
        font-weight: normal;
        line-height: 1;
        color: #FFFFFF; }
    .main_newsletter .container .main_newsletter_form_container {
      flex-basis: 100%; }
      .main_newsletter .container .main_newsletter_form_container form {
        display: flex;
        justify-content: flex-end;
        padding-left: 80px;
        /*960px*/
        /*640px*/ }
        @media (max-width: 60em) {
          .main_newsletter .container .main_newsletter_form_container form {
            padding-left: 0; } }
        @media (max-width: 40em) {
          .main_newsletter .container .main_newsletter_form_container form {
            flex-wrap: wrap;
            padding-left: 0; } }
        .main_newsletter .container .main_newsletter_form_container form label {
          flex-basis: calc(50% - 5px);
          margin-right: 10px;
          /*640px*/ }
          @media (max-width: 40em) {
            .main_newsletter .container .main_newsletter_form_container form label {
              flex-basis: 100%;
              margin-right: 0;
              margin-bottom: 15px; } }
          .main_newsletter .container .main_newsletter_form_container form label input {
            width: 100%;
            padding: 10px 20px;
            border-radius: 25px;
            font-size: 1.2em;
            border: none;
            font-family: 'Titillium Web', sans-serif; }
        .main_newsletter .container .main_newsletter_form_container form button {
          border-radius: 25px;
          color: #FFFFFF;
          background: #5A5B5E;
          font-weight: 700;
          padding: 6px 12px;
          border: 2px solid #5A5B5E;
          width: 145px;
          font-size: 1.1em;
          /*640px*/ }
          @media (max-width: 40em) {
            .main_newsletter .container .main_newsletter_form_container form button {
              padding: 12px 12px;
              margin: 0 auto; } }
          .main_newsletter .container .main_newsletter_form_container form button:hover {
            border-color: #555555;
            background: #6B6C6E; }

/*#### MAIN FOOTER ####*/
.main_footer {
  background: #5A5B5E;
  padding-top: 100px; }
  .main_footer .main_footer_contact {
    margin: 0 auto;
    background: #FFFFFF;
    width: 90%;
    max-width: 1160px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
    /*1072px*/ }
    @media (max-width: 67em) {
      .main_footer .main_footer_contact {
        flex-wrap: wrap;
        padding: 30px 60px 60px; } }
    .main_footer .main_footer_contact .main_footer_contact_item {
      display: flex;
      align-items: center;
      color: #5A5B5E;
      /*1072px*/ }
      @media (max-width: 67em) {
        .main_footer .main_footer_contact .main_footer_contact_item {
          flex-basis: 100%;
          max-width: 300px;
          margin: 0 auto 20px; }
          .main_footer .main_footer_contact .main_footer_contact_item:first-of-type {
            border-top: 4px solid #F36A1F;
            padding-top: 60px; } }
      .main_footer .main_footer_contact .main_footer_contact_item img {
        width: 20px;
        margin-right: 20px; }
      .main_footer .main_footer_contact .main_footer_contact_item a {
        color: #5A5B5E;
        word-break: break-word; }
        .main_footer .main_footer_contact .main_footer_contact_item a:hover {
          text-decoration: underline; }

/*####### BANNER ######*/
.main_banner {
  padding: 0 0 22px 0;
  background: #F36A1F; }
  .main_banner .main_banner_container {
    background: #FFFFFF; }
  .main_banner .swiper {
    position: relative; }
    .main_banner .swiper .swiper-slide {
      position: relative;
      width: 100%;
      height: 850px;
      background-size: cover;
      /*960px*/ }
      @media screen and (max-width: 60em) {
        .main_banner .swiper .swiper-slide {
          height: 500px; } }
      .main_banner .swiper .swiper-slide header {
        max-width: 860px;
        width: 90%; }
        .main_banner .swiper .swiper-slide header h1 {
          font-size: 4em;
          color: #FFFFFF;
          font-weight: 700;
          line-height: 1;
          /*960px*/ }
          @media screen and (max-width: 60em) {
            .main_banner .swiper .swiper-slide header h1 {
              font-size: 2.4em;
              margin-bottom: 5px; } }
        .main_banner .swiper .swiper-slide header p {
          color: #FFFFFF;
          font-weight: 200;
          font-size: 1.4em;
          letter-spacing: 0.5em;
          position: relative;
          padding-left: 20px;
          display: inline;
          /*960px*/ }
          @media screen and (max-width: 60em) {
            .main_banner .swiper .swiper-slide header p {
              font-size: 1.1em; } }
      .main_banner .swiper .swiper-slide .slide_link {
        position: absolute;
        width: 100%;
        height: 100%; }
    .main_banner .swiper .contact_link {
      background: #F36A1F;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 100px;
      padding: 8px 25px 8px 15px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      z-index: 99998;
      font-weight: 700;
      color: #FFFFFF;
      font-size: 1.1em;
      display: flex;
      align-items: center;
      justify-content: center; }
      .main_banner .swiper .contact_link img {
        width: 28px;
        height: 28px;
        margin-right: 10px; }

/*#### ABOUT ####*/
.main_about .container {
  width: 1280px;
  margin-top: -100px;
  border-radius: 30px;
  padding: 80px 0;
  background: #FFFFFF;
  position: relative;
  z-index: 99997; }
  .main_about .container .main_about_container {
    max-width: 90%;
    width: 860px;
    margin: 0 auto; }
    .main_about .container .main_about_container h1 {
      font-size: 1.8em;
      color: #F36A1F;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 1; }
    .main_about .container .main_about_container .htmlchars {
      color: #6D6E71;
      font-size: 0.875em;
      margin-bottom: 20px;
      padding-left: 20px; }
    .main_about .container .main_about_container .main_about_link {
      color: #F36A1F;
      border: 2px solid #F36A1F;
      border-radius: 25px;
      font-weight: 700;
      padding: 3px 10px 3px 25px;
      margin-left: 20px;
      transition: 0.5s; }
      .main_about .container .main_about_container .main_about_link:hover {
        background: #f0f0f0; }
      .main_about .container .main_about_container .main_about_link span {
        margin-left: 10px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #F36A1F; }

/*#### PROJECTS ####*/
.main_projects {
  background: #F36A1F url("../images/bg-projects.png") no-repeat right center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 150px 0;
  /*960px*/
  /*768px*/
  /*640px*/
  /*512px*/ }
  @media (max-width: 60em) {
    .main_projects {
      background-size: contain; } }
  @media (max-width: 48em) {
    .main_projects {
      padding: 80px; } }
  @media (max-width: 40em) {
    .main_projects {
      padding: 50px; } }
  @media (max-width: 32em) {
    .main_projects {
      background-image: none; } }
  .main_projects .main_projects_container {
    color: #FFFFFF; }
    .main_projects .main_projects_container h1 {
      font-size: 1.8em;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 1; }
    .main_projects .main_projects_container .htmlchars {
      padding-left: 20px;
      width: 500px;
      max-width: 90%;
      margin-bottom: 20px; }
    .main_projects .main_projects_container .main_projects_link {
      margin-left: 20px;
      border: 2px solid #FFFFFF;
      border-radius: 25px;
      font-weight: 700;
      padding: 3px 10px 3px 25px;
      color: #FFFFFF;
      transition: 0.5s; }
      .main_projects .main_projects_container .main_projects_link:hover {
        background: rgba(0, 0, 0, 0.1); }
      .main_projects .main_projects_container .main_projects_link span {
        margin-left: 10px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #FFFFFF; }

/*#### CONTACT ####*/
.main_contact {
  padding: 80px 0; }
  .main_contact article {
    display: flex;
    justify-content: space-between;
    /*960px*/ }
    @media (max-width: 60em) {
      .main_contact article {
        flex-wrap: wrap; } }
    .main_contact article h1, .main_contact article h2, .main_contact article h3 {
      font-size: 1.8em;
      font-weight: 700;
      margin-bottom: 15px;
      color: #F36A1F;
      line-height: 1; }
    .main_contact article .main_contact_container_form {
      order: 1;
      max-width: 640px;
      width: 90%;
      padding: 0 0 0 50px;
      /*960px*/ }
      @media (max-width: 60em) {
        .main_contact article .main_contact_container_form {
          max-width: 100%;
          flex-basis: 100%;
          width: 100%;
          padding: 0; } }
      .main_contact article .main_contact_container_form form {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-left: 30px; }
        .main_contact article .main_contact_container_form form label {
          flex-basis: 100%;
          margin-bottom: 20px; }
          .main_contact article .main_contact_container_form form label input, .main_contact article .main_contact_container_form form label textarea, .main_contact article .main_contact_container_form form label select {
            width: 100%;
            padding: 6px 12px;
            border-radius: 25px;
            font-size: 1.2em;
            border: 1px solid #5A5B5E;
            resize: vertical;
            font-family: 'Titillium Web', sans-serif; }
        .main_contact article .main_contact_container_form form .main_contact_form_select_buttom {
          flex-basis: 100%;
          display: flex;
          /*480px*/
          /*480px*/ }
          @media (max-width: 30em) {
            .main_contact article .main_contact_container_form form .main_contact_form_select_buttom {
              flex-wrap: wrap; } }
          .main_contact article .main_contact_container_form form .main_contact_form_select_buttom label {
            flex-basis: calc(50% - 5px);
            margin-bottom: 0;
            margin-right: 10px;
            /*480px*/ }
            .main_contact article .main_contact_container_form form .main_contact_form_select_buttom label:last-of-type {
              margin-right: 0; }
            @media (max-width: 30em) {
              .main_contact article .main_contact_container_form form .main_contact_form_select_buttom label {
                flex-basis: 100%;
                margin: 0; }
                .main_contact article .main_contact_container_form form .main_contact_form_select_buttom label:last-of-type {
                  margin-top: 20px; } }
        .main_contact article .main_contact_container_form form .main_contact_form_buttom {
          display: flex;
          flex-basis: 100%;
          justify-content: center;
          margin-top: 20px; }
          .main_contact article .main_contact_container_form form .main_contact_form_buttom button {
            border-radius: 25px;
            color: #FFFFFF;
            background: #5A5B5E;
            font-weight: 700;
            padding: 12px;
            border: 2px solid #5A5B5E;
            width: 100%;
            max-width: 230px;
            font-size: 1.1em; }
            .main_contact article .main_contact_container_form form .main_contact_form_buttom button:hover {
              border-color: #333333;
              opacity: 0.8; }
    .main_contact article .main_contact_project {
      max-width: 480px;
      width: 90%;
      /*960px*/ }
      @media (max-width: 60em) {
        .main_contact article .main_contact_project {
          max-width: 100%;
          width: 100%;
          flex-basis: 100%; } }
      .main_contact article .main_contact_project .main_contact_project_address {
        border: 1px solid #707070;
        border-radius: 25px;
        padding: 25px;
        margin-top: 15px;
        /*960px*/ }
        @media (max-width: 60em) {
          .main_contact article .main_contact_project .main_contact_project_address {
            max-width: 90%;
            width: 480px;
            margin-bottom: 30px; } }
        .main_contact article .main_contact_project .main_contact_project_address h3 {
          font-size: 1.4em;
          line-height: 1; }
        .main_contact article .main_contact_project .main_contact_project_address a {
          color: #F36A1F;
          font-weight: 700;
          font-size: 1.1em;
          margin: 5px 0; }
          .main_contact article .main_contact_project .main_contact_project_address a:hover {
            opacity: 0.9; }
      .main_contact article .main_contact_project .main_contact_project_map {
        border-radius: 25px;
        margin-top: 30px;
        margin-bottom: 30px;
        overflow: hidden;
        position: relative; }
        .main_contact article .main_contact_project .main_contact_project_map iframe {
          width: 100%;
          height: 280px;
          border: none; }

.main_banner.company {
  /*#### banner ####*/ }
  .main_banner.company .swiper {
    /*#### contact button | banner ####*/ }
    .main_banner.company .swiper .swiper-slide {
      height: 600px;
      /*960px*/ }
      @media screen and (max-width: 60em) {
        .main_banner.company .swiper .swiper-slide {
          height: 500px; } }
    .main_banner.company .swiper .contact_link {
      margin-bottom: 30px; }

.company_content {
  margin-top: 30px; }
  .company_content header h1 {
    font-size: 2.4em;
    font-weight: 700;
    color: #F36A1F;
    margin-bottom: 50px;
    line-height: 1;
    /*480px*/ }
    @media (max-width: 30em) {
      .company_content header h1 {
        padding: 0;
        text-align: center; } }
  .company_content .htmlchars {
    padding: 0 50px;
    color: #5A5B5E; }
    .company_content .htmlchars h1, .company_content .htmlchars h2, .company_content .htmlchars h3, .company_content .htmlchars h4, .company_content .htmlchars h5, .company_content .htmlchars h6 {
      font-size: 1.8em;
      font-weight: 700;
      color: #F36A1F;
      line-height: 1; }
    .company_content .htmlchars p {
      padding: 0 20px; }
  .company_content .company_clients {
    padding: 0 50px;
    margin: 30px 0; }
    .company_content .company_clients h2 {
      font-size: 1.8em;
      font-weight: 700;
      color: #F36A1F;
      line-height: 1; }
    .company_content .company_clients .company_clients_items {
      display: flex;
      flex-wrap: wrap; }
      .company_content .company_clients .company_clients_items .company_clients_item {
        flex-basis: calc(25% - 20px);
        margin: 10px;
        /*1184px*/
        /*768px*/
        /*480px*/ }
        @media screen and (max-width: 74em) {
          .company_content .company_clients .company_clients_items .company_clients_item {
            flex-basis: calc(33.33% - 20px); } }
        @media screen and (max-width: 48em) {
          .company_content .company_clients .company_clients_items .company_clients_item {
            flex-basis: calc(50% - 20px); } }
        @media screen and (max-width: 30em) {
          .company_content .company_clients .company_clients_items .company_clients_item {
            flex-basis: calc(100% - 20px); } }
  .company_content .company_partners {
    margin: 50px 0 100px 0;
    display: flex;
    align-items: end;
    justify-content: flex-start;
    /*960px*/ }
    @media screen and (max-width: 60em) {
      .company_content .company_partners {
        flex-wrap: wrap; } }
    .company_content .company_partners .container {
      margin-left: 10%;
      text-align: right;
      padding: 10px 30px;
      /*960px*/ }
      @media screen and (max-width: 60em) {
        .company_content .company_partners .container {
          margin-bottom: 10px;
          margin-right: 10%; } }
      .company_content .company_partners .container h2 {
        font-size: 1.4em;
        font-weight: 700;
        color: #F36A1F;
        line-height: 1; }
      .company_content .company_partners .container p {
        color: #5A5B5E; }
    .company_content .company_partners img {
      /*1184px*/
      /*960px*/ }
      @media screen and (max-width: 74em) {
        .company_content .company_partners img {
          width: 550px; } }
      @media screen and (max-width: 60em) {
        .company_content .company_partners img {
          width: 90%;
          margin-left: 10%; } }

.main_banner.services {
  /*#### banner ####*/ }
  .main_banner.services .swiper {
    /*#### contact button | banner ####*/ }
    .main_banner.services .swiper .swiper-slide {
      height: 680px;
      /*960px*/ }
      @media screen and (max-width: 60em) {
        .main_banner.services .swiper .swiper-slide {
          height: 500px; } }
    .main_banner.services .swiper .contact_link {
      margin-bottom: 30px; }

.services_content {
  margin-top: 30px; }
  .services_content header h1 {
    font-size: 2.4em;
    font-weight: 700;
    color: #F36A1F;
    margin-bottom: 50px;
    padding: 0 50px;
    line-height: 1;
    /*768px*/
    /*480px*/ }
    @media screen and (max-width: 48em) {
      .services_content header h1 {
        padding: 0; } }
    @media (max-width: 30em) {
      .services_content header h1 {
        text-align: center; } }
  .services_content h2 {
    font-size: 1.8em;
    font-weight: 700;
    color: #F36A1F;
    line-height: 1; }
  .services_content p.p-relative {
    padding: 0 20px 0 40px;
    margin-bottom: 20px;
    color: #6D6E71; }
    .services_content p.p-relative span {
      margin-left: 20px; }
  .services_content .services_bg {
    background: url("../images/bg-orange.png") no-repeat left bottom;
    padding-bottom: 200px; }
  .services_content .services_items {
    margin-top: 30px;
    margin-left: 10%;
    padding: 100px 10% 100px 50px;
    border: 1px solid #707070;
    border-right: none;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background: #FFFFFF; }
    .services_content .services_items .container {
      display: flex;
      flex-wrap: wrap; }
      .services_content .services_items .container .services_item {
        flex-basis: calc(33.33% - 30px);
        margin: 15px;
        /*1080px*/
        /*768px*/ }
        @media screen and (max-width: 67.5em) {
          .services_content .services_items .container .services_item {
            flex-basis: calc(50% - 30px); } }
        @media screen and (max-width: 48em) {
          .services_content .services_items .container .services_item {
            flex-basis: calc(100% - 30px); } }
        .services_content .services_items .container .services_item h3 {
          font-size: 1.8em;
          font-weight: 700;
          line-height: 1; }
          .services_content .services_items .container .services_item h3 a {
            color: #F36A1F; }
            .services_content .services_items .container .services_item h3 a:hover {
              opacity: 0.8;
              text-decoration: underline; }
        .services_content .services_items .container .services_item p a {
          color: #6D6E71; }
          .services_content .services_items .container .services_item p a:hover {
            color: #333333;
            text-decoration: underline; }

/*#### SINGLE SERVICE ####*/
.single_service {
  margin-top: 130px; }
  .single_service .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px; }
    .single_service .container .breadcrumb {
      padding-right: 30px; }
    .single_service .container .back a {
      color: #5A5B5E;
      letter-spacing: 5px; }
      .single_service .container .back a:hover {
        color: #000000; }
  .single_service .single_service_container {
    border-radius: 25px;
    border: 1px solid #5A5B5E;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin: 30px 0 60px; }
    @media (max-width: 65em) {
      .single_service .single_service_container {
        flex-wrap: wrap;
        border-right: 1px solid #5A5B5E; } }
    .single_service .single_service_container .single_service_description {
      width: 40%;
      padding: 25px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-right: 20px solid #F36A1F; }
      @media (max-width: 65em) {
        .single_service .single_service_container .single_service_description {
          flex-basis: 100%;
          border-right: none;
          border-bottom: 20px solid #F36A1F; } }
      .single_service .single_service_container .single_service_description header h1 {
        font-size: 1.8em;
        color: #F36A1F;
        font-weight: normal;
        line-height: 1.2; }
        .single_service .single_service_container .single_service_description header h1 strong {
          display: block;
          font-weight: 700; }
      .single_service .single_service_container .single_service_description header p {
        color: #5A5B5E;
        margin-top: 20px; }
    .single_service .single_service_container .single_service_slide {
      width: 60%;
      display: flex; }
      @media (max-width: 65em) {
        .single_service .single_service_container .single_service_slide {
          flex-basis: 100%; }
          .single_service .single_service_container .single_service_slide .swiper-slide a, .single_service .single_service_container .single_service_slide .swiper-slide img {
            width: 100%; } }
  .single_service aside .container .single_service_related {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px; }
    .single_service aside .container .single_service_related h2 {
      flex-basis: 100%;
      font-size: 2em;
      color: #F36A1F;
      line-height: 1;
      margin-bottom: 30px; }
      @media (max-width: 60em) {
        .single_service aside .container .single_service_related h2 {
          text-align: center; } }
    .single_service aside .container .single_service_related .single_service_related_items {
      flex-basis: 100%;
      display: flex; }
      @media (max-width: 60em) {
        .single_service aside .container .single_service_related .single_service_related_items {
          flex-wrap: wrap;
          justify-content: center; } }
      .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item {
        flex-basis: calc(33.33% - 10px);
        margin-right: 15px;
        border-radius: 25px;
        border: 1px solid #5A5B5E;
        padding: 25px 25px 70px;
        position: relative; }
        @media (max-width: 60em) {
          .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item {
            flex-basis: 100%;
            max-width: 400px;
            margin-right: 0;
            margin-bottom: 30px; }
            .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item:last-of-type {
              margin-bottom: 0; } }
        .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item:nth-of-type(3n+0) {
          margin-right: 0; }
        .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item h1 {
          font-size: 1.8em;
          color: #F36A1F;
          line-height: 1;
          margin-bottom: 20px; }
        .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item a {
          border-radius: 25px;
          border: 3px solid #F36A1F;
          color: #F36A1F;
          font-weight: 700;
          padding: 5px 20px 5px 35px;
          position: absolute;
          bottom: 0;
          margin: 10px 0; }
          .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item a:hover {
            border-color: #FF5C03;
            color: #FF5C03; }
          .single_service aside .container .single_service_related .single_service_related_items .single_service_related_item a span {
            margin-left: 15px; }

.main_banner.products {
  /*#### banner ####*/ }
  .main_banner.products .swiper {
    /*#### contact button | banner ####*/ }
    .main_banner.products .swiper .swiper-slide {
      height: 680px;
      /*960px*/ }
      @media screen and (max-width: 60em) {
        .main_banner.products .swiper .swiper-slide {
          height: 500px; } }
    .main_banner.products .swiper .contact_link {
      margin-bottom: 30px; }

/*#### PRODUCTS LIST ####*/
.products_content header h1 {
  font-size: 2.4em;
  font-weight: 700;
  color: #F36A1F;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 1;
  /*480px*/ }
  @media (max-width: 30em) {
    .products_content header h1 {
      text-align: center; } }

.products_content hr {
  border-color: #D8D8D8;
  opacity: 0.5;
  margin-bottom: 30px; }

.products_content h2 {
  font-size: 1.8em;
  font-weight: 700;
  color: #F36A1F;
  margin-bottom: 30px;
  line-height: 1; }

.products_content .products_items {
  display: flex;
  /*640px*/ }
  @media (max-width: 40em) {
    .products_content .products_items {
      flex-wrap: wrap; } }
  .products_content .products_items .products_item:nth-of-type(1) {
    flex-basis: 20%;
    /*768px*/
    /*640px*/ }
    @media (max-width: 48em) {
      .products_content .products_items .products_item:nth-of-type(1) {
        flex-basis: 30%; } }
    @media (max-width: 40em) {
      .products_content .products_items .products_item:nth-of-type(1) {
        flex-basis: 100%; } }
    .products_content .products_items .products_item:nth-of-type(1) .products_item_nav {
      border-radius: 25px;
      border: 1px solid #000000;
      padding: 25px;
      margin: 0 10px; }
      .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li {
        margin-bottom: 20px; }
        .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li:last-of-type {
          margin-bottom: 0; }
        .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li a {
          color: #5A5B5E;
          font-weight: 600; }
          .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li a:hover {
            color: #000000; }
          .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li a.active {
            color: #F36A1F;
            font-weight: 700; }
        .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li ul {
          padding-left: 10px; }
          .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li ul li {
            margin-bottom: 5px;
            line-height: 1; }
            .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li ul li a {
              color: #000000; }
              .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li ul li a:hover {
                opacity: 0.8; }
              .products_content .products_items .products_item:nth-of-type(1) .products_item_nav li ul li a.active {
                color: #F36A1F;
                font-weight: 700; }
  .products_content .products_items .products_item:nth-of-type(2) {
    flex-basis: 80%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    /*768px*/
    /*640px*/ }
    @media (max-width: 48em) {
      .products_content .products_items .products_item:nth-of-type(2) {
        flex-basis: 70%; } }
    @media (max-width: 40em) {
      .products_content .products_items .products_item:nth-of-type(2) {
        flex-basis: 100%;
        padding-left: 0;
        margin-top: 30px; } }
    .products_content .products_items .products_item:nth-of-type(2) article {
      flex-basis: calc(33.33% - 20px);
      margin: 0 10px 20px 10px;
      /*768px*/
      /*480px*/ }
      @media (max-width: 48em) {
        .products_content .products_items .products_item:nth-of-type(2) article {
          flex-basis: calc(50% - 20px); } }
      @media (max-width: 30em) {
        .products_content .products_items .products_item:nth-of-type(2) article {
          flex-basis: calc(100% - 20px); } }
      .products_content .products_items .products_item:nth-of-type(2) article .products_item_image {
        border: 1px solid #000000;
        border-radius: 25px;
        overflow: hidden; }
        .products_content .products_items .products_item:nth-of-type(2) article .products_item_image:hover {
          border-color: #999999; }
        .products_content .products_items .products_item:nth-of-type(2) article .products_item_image a {
          width: 100%;
          height: 250px;
          display: block; }
      .products_content .products_items .products_item:nth-of-type(2) article h1 {
        font-size: 1.2em;
        text-align: center;
        margin-top: 20px; }
        .products_content .products_items .products_item:nth-of-type(2) article h1 a {
          color: #5A5B5E; }
          .products_content .products_items .products_item:nth-of-type(2) article h1 a:hover {
            color: #F36A1F; }

/*#### NO PRODUCTS ####*/
.no_products {
  margin-top: 30px;
  padding-bottom: 50px; }
  .no_products .container {
    display: flex;
    justify-content: space-between;
    /*768px*/ }
    @media (max-width: 48em) {
      .no_products .container {
        flex-wrap: wrap;
        justify-content: center; }
        .no_products .container .products_content_items {
          margin-bottom: 50px; } }
    .no_products .container .products_content_items:nth-of-type(1) .products_content_item {
      max-width: 450px;
      width: 90%; }
      .no_products .container .products_content_items:nth-of-type(1) .products_content_item header h1 {
        font-size: 2.4em;
        font-weight: 700;
        color: #F36A1F;
        margin-bottom: 30px;
        padding: 0 50px;
        line-height: 1;
        /*480px*/ }
        @media (max-width: 30em) {
          .no_products .container .products_content_items:nth-of-type(1) .products_content_item header h1 {
            padding: 0; } }
      .no_products .container .products_content_items:nth-of-type(1) .products_content_item p {
        padding: 0 50px;
        color: #5A5B5E;
        /*480px*/ }
        @media (max-width: 30em) {
          .no_products .container .products_content_items:nth-of-type(1) .products_content_item p {
            padding: 0; } }
      .no_products .container .products_content_items:nth-of-type(1) .products_content_item .link {
        display: inline-block;
        margin: 20px 0 20px 50px;
        border: 2px solid #F36A1F;
        border-radius: 25px;
        padding: 6px 10px 6px 30px;
        color: #F36A1F;
        font-weight: 700; }
        .no_products .container .products_content_items:nth-of-type(1) .products_content_item .link:hover {
          background: #f0f0f0; }
        .no_products .container .products_content_items:nth-of-type(1) .products_content_item .link span {
          margin-left: 10px; }
    .no_products .container .products_content_items:nth-of-type(2) .products_content_item {
      border: 1px solid #707070;
      border-radius: 25px;
      padding: 25px;
      color: #5A5B5E; }
      .no_products .container .products_content_items:nth-of-type(2) .products_content_item h2 {
        font-size: 1.4em;
        font-weight: 700;
        color: #F36A1F;
        line-height: 1; }
      .no_products .container .products_content_items:nth-of-type(2) .products_content_item a {
        color: #F36A1F;
        font-weight: 700; }

/*#### HEADER ####*/
.main_header .main_header_container.header_margin_top {
  margin-top: 0; }

/*#### SINGLE PRODUCT ####*/
.single_product {
  margin-top: 130px; }
  .single_product .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px; }
    .single_product .container .breadcrumb {
      padding-right: 30px; }
    .single_product .container .back a {
      color: #5A5B5E;
      letter-spacing: 5px; }
      .single_product .container .back a:hover {
        color: #000000; }
  @media (max-width: 60em) {
    .single_product article .container:first-of-type {
      flex-wrap: wrap;
      margin-top: 30px;
      justify-content: center; }
      .single_product article .container:first-of-type .single_product_title {
        flex-basis: 100%;
        max-width: 100%;
        order: 1;
        margin-top: 30px;
        margin-bottom: 30px; }
      .single_product article .container:first-of-type .single_product_images {
        flex-basis: 100%;
        max-width: 100%; } }
  .single_product article .container {
    flex-wrap: nowrap;
    align-items: center; }
    .single_product article .container .single_product_title {
      border: 1px solid #5A5B5E;
      padding: 25px;
      border-radius: 25px;
      max-width: 480px;
      width: 90%; }
      .single_product article .container .single_product_title h1 {
        font-size: 2.4em;
        font-weight: 700;
        color: #F36A1F;
        line-height: 1;
        margin-bottom: 30px; }
      .single_product article .container .single_product_title p {
        color: #5A5B5E; }
    .single_product article .container .single_product_images {
      width: 100%;
      max-width: 480px; }
    .single_product article .container .htmlchars {
      margin: 50px 0; }
      @media (max-width: 60em) {
        .single_product article .container .htmlchars img {
          width: 100% !important; } }

.main_banner.occupation {
  /*#### banner ####*/ }
  .main_banner.occupation .swiper {
    /*#### contact button | banner ####*/ }
    .main_banner.occupation .swiper .swiper-slide {
      height: 680px;
      /*960px*/ }
      @media screen and (max-width: 60em) {
        .main_banner.occupation .swiper .swiper-slide {
          height: 500px; } }
    .main_banner.occupation .swiper .contact_link {
      margin-bottom: 30px; }

.occupation_content header h1 {
  font-size: 2.4em;
  font-weight: 700;
  color: #F36A1F;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 50px;
  line-height: 1;
  /*480px*/ }
  @media (max-width: 30em) {
    .occupation_content header h1 {
      padding: 0;
      text-align: center; } }

.occupation_content .occupation_items {
  display: flex;
  flex-wrap: wrap; }
  .occupation_content .occupation_items .occupation_item {
    flex-basis: calc(25% - 20px);
    margin: 50px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    /*768px*/
    /*480px*/
    /*352px*/ }
    @media (max-width: 48em) {
      .occupation_content .occupation_items .occupation_item {
        flex-basis: calc(33.33% - 20px); } }
    @media (max-width: 48em) {
      .occupation_content .occupation_items .occupation_item {
        flex-basis: calc(50% - 20px); } }
    @media (max-width: 22em) {
      .occupation_content .occupation_items .occupation_item {
        flex-basis: calc(100% - 20px); } }
    .occupation_content .occupation_items .occupation_item img {
      height: 70px;
      margin-bottom: 40px; }
    .occupation_content .occupation_items .occupation_item h1 {
      flex-basis: 100%;
      text-align: center;
      font-weight: 400; }
      .occupation_content .occupation_items .occupation_item h1 a {
        color: #5A5B5E; }
        .occupation_content .occupation_items .occupation_item h1 a:hover {
          font-weight: 700; }

/*#### CONTACT ####*/
.occupation_contact {
  padding-bottom: 0;
  /*1152px*/ }
  @media (max-width: 72em) {
    .occupation_contact article {
      flex-wrap: wrap;
      justify-content: center; } }
  .occupation_contact .main_contact_container_form {
    flex-basis: 70%;
    max-width: 100% !important;
    background: #F36A1F;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 100px 10% 100px 80px !important;
    width: 100% !important;
    /*1152px*/ }
    @media (max-width: 72em) {
      .occupation_contact .main_contact_container_form {
        margin-top: 50px;
        padding: 100px 10% !important; } }
    .occupation_contact .main_contact_container_form form {
      background: #FFFFFF;
      padding: 100px 50px !important;
      border-radius: 25px; }
      .occupation_contact .main_contact_container_form form header {
        width: 100%; }
  .occupation_contact .main_contact_project {
    margin-left: 10%;
    padding-right: 30px;
    /*960px*/ }
    @media (max-width: 60em) {
      .occupation_contact .main_contact_project {
        padding: 40px 10% !important;
        margin: 0 10%;
        text-align: center; }
        .occupation_contact .main_contact_project .main_contact_project_address {
          margin: 0 auto; } }

/*#### SINGLE OCCUPATION ####*/
.single_occupation .single_occupation_icone {
  display: flex;
  align-items: center; }
  .single_occupation .single_occupation_icone img {
    width: 50px;
    margin-right: 10px; }
  .single_occupation .single_occupation_icone h1 strong {
    display: inline !important; }

.single_occupation article .container .htmlchars {
  padding: 0 50px;
  color: #707070;
  margin-bottom: 100px; }

.single_occupation .single_service_related_item {
  padding: 25px !important;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.5s; }
  .single_occupation .single_service_related_item:hover {
    border: 1px solid #F36A1F !important; }
    .single_occupation .single_service_related_item:hover h1 {
      transition: 0.5s;
      color: #F36A1F !important; }
  .single_occupation .single_service_related_item a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border: none !important; }
  .single_occupation .single_service_related_item h1 {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    font-weight: normal !important;
    font-size: 1.6em !important;
    color: #5A5B5E !important;
    word-break: break-word !important; }
    .single_occupation .single_service_related_item h1 img {
      width: 50px;
      margin-right: 15px; }

/*#### BREADCRUMB ####*/
.breadcrumb {
  padding: 30px 0;
  color: #5A5B5E;
  letter-spacing: 5px;
  font-size: 1.1em; }
  .breadcrumb .container {
    padding: 0 50px; }
  .breadcrumb a {
    color: #5A5B5E; }
    .breadcrumb a:hover {
      text-decoration: underline; }
  .breadcrumb span {
    color: #F36A1F;
    font-weight: 700; }

.fancybox__container {
  z-index: 99999; }

.ajax_load {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999 !important; }

.ajax_load_box {
  margin: auto;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }

.ajax_load_box_circle {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #055B9D;
  border-radius: 50%;
  margin: auto;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1.2s linear infinite;
  -o-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite; }

.ajax_load_box_title {
  font-size: 2em; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

select {
  background: #FFFFFF !important; }
