/*#### HEADER ####*/
.main_header .main_header_container.header_margin_top{
  margin-top: 0;
}

/*#### SINGLE PRODUCT ####*/
.single_product {
  margin-top: 130px;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    .breadcrumb{
      padding-right: 30px;
    }

    .back {
      a {
        color: #5A5B5E;
        letter-spacing: 5px;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  article {
    @media (max-width: 60em){
      .container:first-of-type{
        flex-wrap: wrap;
        margin-top: 30px;
        justify-content: center;

        .single_product_title{
          flex-basis: 100%;
          max-width: 100%;
          order: 1;
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .single_product_images{
          flex-basis: 100%;
          max-width: 100%;

        }
      }
    }

    .container {
      flex-wrap: nowrap;
      align-items: center;

      .single_product_title {
        border: 1px solid #5A5B5E;
        padding: 25px;
        border-radius: 25px;
        max-width: 480px;
        width: 90%;

        h1 {
          font-size: 2.4em;
          font-weight: 700;
          color: #F36A1F;
          line-height: 1;
          margin-bottom: 30px;
        }

        p {
          color: #5A5B5E;
        }
      }

      .single_product_images {
        width: 100%;
        max-width: 480px;
      }

      .htmlchars{
        margin: 50px 0;

        img{
          @media (max-width: 60em){
            width: 100% !important;
          }
        }
      }
    }
  }

}