.main_banner.occupation {
  /*#### banner ####*/
  .swiper {
    .swiper-slide {
      height: 680px;

      /*960px*/
      @media screen and (max-width: 60em) {
        height: 500px;
      }
    }

    /*#### contact button | banner ####*/
    .contact_link {
      margin-bottom: 30px;
    }
  }
}

.occupation_content {
  header h1 {
    font-size: 2.4em;
    font-weight: 700;
    color: #F36A1F;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 50px;
    line-height: 1;

    /*480px*/
    @media (max-width: 30em) {
      padding: 0;
      text-align: center;
    }
  }

  .occupation_items {
    display: flex;
    flex-wrap: wrap;

    .occupation_item {
      flex-basis: calc(25% - 20px);
      margin: 50px 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;

      /*768px*/
      @media (max-width: 48em) {
        flex-basis: calc(33.33% - 20px);
      }

      /*480px*/
      @media (max-width: 48em) {
        flex-basis: calc(50% - 20px);
      }

      /*352px*/
      @media (max-width: 22em) {
        flex-basis: calc(100% - 20px);
      }

      img {
        height: 70px;
        margin-bottom: 40px;
      }

      h1 {
        flex-basis: 100%;
        text-align: center;
        font-weight: 400;

        a {
          color: #5A5B5E;

          &:hover {
            font-weight: 700;
          }
        }
      }
    }
  }
}

/*#### CONTACT ####*/
.occupation_contact {
  padding-bottom: 0;

  /*1152px*/
  @media (max-width: 72em) {
    article {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .main_contact_container_form {
    flex-basis: 70%;
    max-width: 100% !important;
    background: #F36A1F;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 100px 10% 100px 80px !important;
    width: 100% !important;

    /*1152px*/
    @media (max-width: 72em) {
      margin-top: 50px;
      padding: 100px 10% !important;
    }

    form {
      background: #FFFFFF;
      padding: 100px 50px !important;
      border-radius: 25px;

      header {
        width: 100%;
      }

    }

  }

  .main_contact_project {
    margin-left: 10%;
    padding-right: 30px;

    /*960px*/
    @media (max-width: 60em) {
      padding: 40px 10% !important;
      margin: 0 10%;
      text-align: center;

      .main_contact_project_address {
        margin: 0 auto;
      }
    }
  }
}

/*#### SINGLE OCCUPATION ####*/
.single_occupation {

  .single_occupation_icone {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      margin-right: 10px;
    }

    h1 {
      strong {
        display: inline !important;
      }
    }
  }

  article {
    .container {
      .htmlchars {
        padding: 0 50px;
        color: #707070;
        margin-bottom: 100px;
      }
    }
  }

  .single_service_related_item {
    padding: 25px !important;
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.5s;

    &:hover {
      border: 1px solid #F36A1F !important;

      h1 {
        transition: 0.5s;
        color: #F36A1F !important;
      }
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 !important;
      border: none !important;
    }

    h1 {
      margin-bottom: 0 !important;
      display: flex;
      align-items: center;
      font-weight: normal !important;
      font-size: 1.6em !important;
      color: #5A5B5E !important;
      word-break: break-word !important;


      img {
        width: 50px;
        margin-right: 15px;
      }
    }
  }
}