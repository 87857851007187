/*####### BANNER ######*/
.main_banner {
  padding: 0 0 22px 0;
  background: #F36A1F;

  .main_banner_container {
    background: #FFFFFF;
  }

  .swiper {
    position: relative;

    .swiper-slide {
      position: relative;
      width: 100%;
      height: 850px;
      background-size: cover;

      /*960px*/
      @media screen and (max-width: 60em) {
        height: 500px;
      }

      header {
        max-width: 860px;
        width: 90%;

        h1 {
          font-size: 4em;
          color: #FFFFFF;
          font-weight: 700;
          line-height: 1;

          /*960px*/
          @media screen and (max-width: 60em) {
            font-size: 2.4em;
            margin-bottom: 5px;
          }
        }

        p {
          color: #FFFFFF;
          font-weight: 200;
          font-size: 1.4em;
          letter-spacing: 0.5em;
          position: relative;
          padding-left: 20px;
          display: inline;

          /*960px*/
          @media screen and (max-width: 60em) {
            font-size: 1.1em;
          }
        }
      }

      .slide_link {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    .contact_link {
      background: #F36A1F;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 100px;
      padding: 8px 25px 8px 15px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      z-index: 99998;
      font-weight: 700;
      color: #FFFFFF;
      font-size: 1.1em;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }
  }
}

/*#### ABOUT ####*/
.main_about {

  .container {
    width: 1280px;
    margin-top: -100px;
    border-radius: 30px;
    padding: 80px 0;
    background: #FFFFFF;
    position: relative;
    z-index: 99997;

    .main_about_container {
      max-width: 90%;
      width: 860px;
      margin: 0 auto;

      h1 {
        font-size: 1.8em;
        color: #F36A1F;
        font-weight: 700;
        margin-bottom: 15px;
        line-height: 1;
      }

      .htmlchars {
        color: #6D6E71;
        font-size: 0.875em;
        margin-bottom: 20px;
        padding-left: 20px;
      }

      .main_about_link {
        color: #F36A1F;
        border: 2px solid #F36A1F;
        border-radius: 25px;
        font-weight: 700;
        padding: 3px 10px 3px 25px;
        margin-left: 20px;
        transition: 0.5s;

        &:hover{
          background: #f0f0f0;
        }

        span {
          margin-left: 10px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 10px solid #F36A1F;
        }
      }
    }
  }
}

/*#### PROJECTS ####*/
.main_projects {
  background: #F36A1F url('../images/bg-projects.png') no-repeat right center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 150px 0;

  /*960px*/
  @media (max-width: 60em) {
    background-size: contain;
  }

  /*768px*/
  @media (max-width: 48em) {
    padding: 80px;
  }

  /*640px*/
  @media (max-width: 40em) {
    padding: 50px;
  }

  /*512px*/
  @media (max-width: 32em) {
    background-image: none;
  }

  .main_projects_container {
    color: #FFFFFF;

    h1 {
      font-size: 1.8em;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 1;
    }

    .htmlchars {
      padding-left: 20px;
      width: 500px;
      max-width: 90%;
      margin-bottom: 20px;
    }

    .main_projects_link {
      margin-left: 20px;
      border: 2px solid #FFFFFF;
      border-radius: 25px;
      font-weight: 700;
      padding: 3px 10px 3px 25px;
      color: #FFFFFF;
      transition: 0.5s;

      &:hover{
        background: rgba(0,0,0,0.1);
      }

      span {
        margin-left: 10px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #FFFFFF;
      }
    }
  }
}

/*#### CONTACT ####*/
.main_contact {
  padding: 80px 0;

  article {
    display: flex;
    justify-content: space-between;

    /*960px*/
    @media (max-width: 60em) {
      flex-wrap: wrap;
    }

    h1, h2, h3 {
      font-size: 1.8em;
      font-weight: 700;
      margin-bottom: 15px;
      color: #F36A1F;
      line-height: 1;
    }

    //FORM CONTACT
    .main_contact_container_form {
      order: 1;
      max-width: 640px;
      width: 90%;
      padding: 0 0 0 50px;

      /*960px*/
      @media (max-width: 60em) {
        max-width: 100%;
        flex-basis: 100%;
        width: 100%;
        padding: 0;
      }

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-left: 30px;

        label {
          flex-basis: 100%;
          margin-bottom: 20px;

          input, textarea, select {
            width: 100%;
            padding: 6px 12px;
            border-radius: 25px;
            font-size: 1.2em;
            border: 1px solid #5A5B5E;
            resize: vertical;
            font-family: 'Titillium Web', sans-serif;
          }
        }

        .main_contact_form_select_buttom {
          flex-basis: 100%;
          display: flex;

          /*480px*/
          @media (max-width: 30em) {
            flex-wrap: wrap;
          }

          label {
            flex-basis: calc(50% - 5px);
            margin-bottom: 0;
            margin-right: 10px;

            &:last-of-type{
              margin-right: 0;
            }

            /*480px*/
            @media (max-width: 30em) {
              flex-basis: 100%;
              margin: 0;

              &:last-of-type{
                margin-top: 20px;
              }
            }

          }

          /*480px*/
          @media (max-width: 30em) {
            //margin: 25px auto 0;
            //padding: 15px;
          }

        }

        .main_contact_form_buttom {
          display: flex;
          flex-basis: 100%;
          justify-content: center;
          margin-top: 20px;

          button {
            border-radius: 25px;
            color: #FFFFFF;
            background: #5A5B5E;
            font-weight: 700;
            padding: 12px;
            border: 2px solid #5A5B5E;
            width: 100%;
            max-width: 230px;
            font-size: 1.1em;

            &:hover {
              border-color: #333333;
              opacity: 0.8;
            }
          }
        }
      }
    }

    .main_contact_project {
      max-width: 480px;
      width: 90%;

      /*960px*/
      @media (max-width: 60em) {
        max-width: 100%;
        width: 100%;
        flex-basis: 100%;
      }

      .main_contact_project_address {
        border: 1px solid #707070;
        border-radius: 25px;
        padding: 25px;
        margin-top: 15px;

        /*960px*/
        @media (max-width: 60em) {
          max-width: 90%;
          width: 480px;
          margin-bottom: 30px;
        }

        h3 {
          font-size: 1.4em;
          line-height: 1;
        }

        a {
          color: #F36A1F;
          font-weight: 700;
          font-size: 1.1em;
          margin: 5px 0;

          &:hover {
            opacity: 0.9;
          }
        }
      }

      .main_contact_project_map {
        border-radius: 25px;
        margin-top: 30px;
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;

        iframe {
          width: 100%;
          height: 280px;
          border: none;
        }


      }
    }
  }
}