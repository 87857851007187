.main_banner.company {
  /*#### banner ####*/
  .swiper {
    .swiper-slide {
      height: 600px;

      /*960px*/
      @media screen and (max-width: 60em) {
        height: 500px;
      }
    }

    /*#### contact button | banner ####*/
    .contact_link {
      margin-bottom: 30px;
    }
  }
}


.company_content {
  margin-top: 30px;

  header h1 {
    font-size: 2.4em;
    font-weight: 700;
    color: #F36A1F;
    margin-bottom: 50px;
    line-height: 1;

    /*480px*/
    @media (max-width: 30em) {
      padding: 0;
      text-align: center;
    }
  }

  .htmlchars {
    padding: 0 50px;
    color: #5A5B5E;

    h1, h2, h3, h4, h5, h6 {
      font-size: 1.8em;
      font-weight: 700;
      color: #F36A1F;
      line-height: 1;
    }

    p{
      padding: 0 20px;
    }
  }

  .company_clients{
    padding: 0 50px;
    margin: 30px 0;

    h2{
      font-size: 1.8em;
      font-weight: 700;
      color: #F36A1F;
      line-height: 1;
    }

    .company_clients_items{
      display: flex;
      flex-wrap: wrap;

      .company_clients_item{
        flex-basis: calc(25% - 20px);
        margin: 10px;

        /*1184px*/
        @media screen and (max-width: 74em) {
          flex-basis: calc(33.33% - 20px);
        }

        /*768px*/
        @media screen and (max-width: 48em) {
          flex-basis: calc(50% - 20px);
        }

        /*480px*/
        @media screen and (max-width: 30em) {
          flex-basis: calc(100% - 20px);
        }
      }
    }
  }

  .company_partners{
    margin: 50px 0 100px 0;
    display: flex;
    align-items: end;
    justify-content: flex-start;

    /*960px*/
    @media screen and (max-width: 60em) {
      flex-wrap: wrap;
    }

    .container{
      margin-left: 10%;
      text-align: right;
      padding: 10px 30px;

      /*960px*/
      @media screen and (max-width: 60em) {
        margin-bottom: 10px;
        margin-right: 10%;
      }

      h2{
        font-size: 1.4em;
        font-weight: 700;
        color: #F36A1F;
        line-height: 1;
      }

      p{
        color: #5A5B5E;
      }
    }

    img{


      /*1184px*/
      @media screen and (max-width: 74em) {
        width: 550px;
      }

      /*960px*/
      @media screen and (max-width: 60em) {
        width: 90%;
        margin-left: 10%;
      }
    }
  }
}