.main_banner.services {
  /*#### banner ####*/
  .swiper {
    .swiper-slide {
      height: 680px;

      /*960px*/
      @media screen and (max-width: 60em) {
        height: 500px;
      }
    }

    /*#### contact button | banner ####*/
    .contact_link {
      margin-bottom: 30px;
    }
  }
}


.services_content {
  margin-top: 30px;

  header h1 {
    font-size: 2.4em;
    font-weight: 700;
    color: #F36A1F;
    margin-bottom: 50px;
    padding: 0 50px;
    line-height: 1;

    /*768px*/
    @media screen and (max-width: 48em) {
      padding: 0;
    }

    /*480px*/
    @media (max-width: 30em) {
      text-align: center;
    }
  }

  h2 {
    font-size: 1.8em;
    font-weight: 700;
    color: #F36A1F;
    line-height: 1;
  }

  p.p-relative {
    padding: 0 20px 0 40px;
    margin-bottom: 20px;
    color: #6D6E71;

    span {
      margin-left: 20px;
    }
  }

  .services_bg {
    background: url("../images/bg-orange.png") no-repeat left bottom;
    padding-bottom: 200px;
  }

  .services_items {
    margin-top: 30px;
    margin-left: 10%;
    padding: 100px 10% 100px 50px;
    border: 1px solid #707070;
    border-right: none;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background: #FFFFFF;

    .container {
      display: flex;
      flex-wrap: wrap;

      .services_item {
        flex-basis: calc(33.33% - 30px);
        margin: 15px;

        /*1080px*/
        @media screen and (max-width: 67.500em) {
          flex-basis: calc(50% - 30px);
        }

        /*768px*/
        @media screen and (max-width: 48em) {
          flex-basis: calc(100% - 30px);
        }

        h3{
          font-size: 1.8em;
          font-weight: 700;
          line-height: 1;

          a{
            color: #F36A1F;

            &:hover{
              opacity: 0.8;
              text-decoration: underline;
            }
          }
        }

        p{
          a{
            color: #6D6E71;

            &:hover{
              color: #333333;
              text-decoration: underline;
            }
          }
        }
      }
    }

  }
}