.main_banner.products {
  /*#### banner ####*/
  .swiper {
    .swiper-slide {
      height: 680px;

      /*960px*/
      @media screen and (max-width: 60em) {
        height: 500px;
      }
    }

    /*#### contact button | banner ####*/
    .contact_link {
      margin-bottom: 30px;
    }
  }
}

/*#### PRODUCTS LIST ####*/
.products_content {
  header h1 {
    font-size: 2.4em;
    font-weight: 700;
    color: #F36A1F;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1;

    /*480px*/
    @media (max-width: 30em) {
      text-align: center;
    }
  }

  hr {
    border-color: #D8D8D8;
    opacity: 0.5;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 1.8em;
    font-weight: 700;
    color: #F36A1F;
    margin-bottom: 30px;
    line-height: 1;
  }

  .products_items {
    display: flex;

    /*640px*/
    @media (max-width: 40em) {
      flex-wrap: wrap;
    }


    .products_item:nth-of-type(1) {
      flex-basis: 20%;

      /*768px*/
      @media (max-width: 48em) {
        flex-basis: 30%;
      }

      /*640px*/
      @media (max-width: 40em) {
        flex-basis: 100%;
      }

      .products_item_nav {
        border-radius: 25px;
        border: 1px solid #000000;
        padding: 25px;
        margin: 0 10px;

        li {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            color: #5A5B5E;
            font-weight: 600;

            &:hover {
              color: #000000;
            }

            &.active {
              color: #F36A1F;
              font-weight: 700;
            }
          }

          ul {
            padding-left: 10px;

            li {
              margin-bottom: 5px;
              line-height: 1;

              a {
                color: #000000;

                &:hover {
                  opacity: 0.8;
                }

                &.active {
                  color: #F36A1F;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    .products_item:nth-of-type(2) {
      flex-basis: 80%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 50px;

      /*768px*/
      @media (max-width: 48em) {
        flex-basis: 70%;
      }

      /*640px*/
      @media (max-width: 40em) {
        flex-basis: 100%;
        padding-left: 0;
        margin-top: 30px;
      }

      article {
        flex-basis: calc(33.33% - 20px);
        margin: 0 10px 20px 10px;

        /*768px*/
        @media (max-width: 48em) {
          flex-basis: calc(50% - 20px);
        }

        /*480px*/
        @media (max-width: 30em) {
          flex-basis: calc(100% - 20px);
        }

        .products_item_image {
          border: 1px solid #000000;
          border-radius: 25px;
          overflow: hidden;

          &:hover {
            border-color: #999999;
          }

          a{
            width: 100%;
            height: 250px;
            display: block;
          }
        }

        h1 {
          font-size: 1.2em;
          text-align: center;
          margin-top: 20px;

          a {
            color: #5A5B5E;

            &:hover {
              color: #F36A1F;
            }
          }
        }
      }
    }
  }
}

/*#### NO PRODUCTS ####*/
.no_products {
  margin-top: 30px;
  padding-bottom: 50px;

  .container {
    display: flex;
    justify-content: space-between;

    /*768px*/
    @media (max-width: 48em) {
      flex-wrap: wrap;
      justify-content: center;

      .products_content_items {
        margin-bottom: 50px;
      }
    }

    .products_content_items:nth-of-type(1) {
      .products_content_item {
        max-width: 450px;
        width: 90%;

        header h1 {
          font-size: 2.4em;
          font-weight: 700;
          color: #F36A1F;
          margin-bottom: 30px;
          padding: 0 50px;
          line-height: 1;

          /*480px*/
          @media (max-width: 30em) {
            padding: 0;
          }
        }

        p {
          padding: 0 50px;
          color: #5A5B5E;

          /*480px*/
          @media (max-width: 30em) {
            padding: 0;
          }
        }

        .link {
          display: inline-block;
          margin: 20px 0 20px 50px;
          border: 2px solid #F36A1F;
          border-radius: 25px;
          padding: 6px 10px 6px 30px;
          color: #F36A1F;
          font-weight: 700;

          &:hover {
            background: #f0f0f0;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .products_content_items:nth-of-type(2) {
      .products_content_item {
        border: 1px solid #707070;
        border-radius: 25px;
        padding: 25px;
        color: #5A5B5E;

        h2 {
          font-size: 1.4em;
          font-weight: 700;
          color: #F36A1F;
          line-height: 1;
        }

        a {
          color: #F36A1F;
          font-weight: 700;
        }
      }
    }


  }
}