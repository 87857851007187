@import 'boot', 'libs','variables', 'common', 'parent', 'home', 'company', 'services', 'service', 'products', 'product', 'occupation';

/*#### BREADCRUMB ####*/
.breadcrumb {
  padding: 30px 0;
  color: #5A5B5E;
  letter-spacing: 5px;
  font-size: 1.1em;

  .container{
    padding: 0 50px;
  }

  a {
    color: #5A5B5E;

    &:hover{
      text-decoration: underline;
    }
  }

  span{
    color: #F36A1F;
    font-weight: 700;
  }
}

.fancybox__container{
  z-index: 99999;
}

.ajax_load {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999 !important;
}

.ajax_load_box {
  margin: auto;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.ajax_load_box_circle {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #055B9D;
  border-radius: 50%;
  margin: auto;
  width: 80px;
  height: 80px;

  -webkit-animation: spin 1.2s linear infinite;
  -o-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
}

.ajax_load_box_title {
  font-size: 2em;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

select{
  background: #FFFFFF !important;
}
